import React, { useEffect, useState } from 'react';
import { DropContainer } from '../DropContainer';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import LotPanel from './components/LotPanel';
import { LockIcon } from '../../../ui/icons';
import LotAreaInfo from './components/LotAreaInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { draggableOptionsDefault } from '../../../ui/components/Cat/draggableOptionsDefault';
import { AI_TYPE, DRAG_AREA, LTD_ACTIONS, ROOT_PATHS, SPECIALITIES } from '../../../common/constants';
import { checkIfHaveLtdWithCurrentPlayer } from '../../../common/LTD/checkIfHaveLtdWithCurrentPlayer';
import PawGrid from './components/PawGrid';

function LotField({ items }) {
  const { appStore } = RootStore;
  let storageCats = appStore.catsLTDFromStorage;

  let groupedCats;
  if (appStore.currentDoctorReviewingLTD) {
    groupedCats = storageCats = appStore.currentDoctorReviewingLTD.content.contents.map((cat) => {
      return { ...cat, hungry: null, sickness: null };
    });
  } else if (storageCats) {
    groupedCats = storageCats;
  } else {
    groupedCats = appStore.groupedLotCats;
  }
  const fromType = !storageCats ? DRAG_AREA.LOT : DRAG_AREA.STORAGE;
  const isOutcoming = appStore.currentLotIsOutcoming;
  const [dragAroundForCatsInLotField, setDragAroundForCatsInLotField] = useState(null);

  const lotFieldVision = appStore.hasSpecialities([SPECIALITIES.SUPPLY, SPECIALITIES.SALES]);

  useEffect(() => {
    const el = document.querySelectorAll('.lot-area__cats .swiper-wrapper')[0];
    if (el) {
      setDragAroundForCatsInLotField(el.offsetWidth >= el.scrollWidth);
    }
  });

  let lockIconFlag;

  const isStorageRoute = RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE;

  const isLtdPartner = checkIfHaveLtdWithCurrentPlayer();

  if ((appStore.isCorpMode || appStore.isRealTeamMode) && isStorageRoute) {
    lockIconFlag = false;
  } else {
    lockIconFlag =
      (!lotFieldVision && !appStore.currentPlayer?.lot) ||
      appStore.me.role === appStore.currentPlayer?.role ||
      ((appStore.currentCompanyBlockingInfo || appStore.blockedByCurrentCompanyInfo) &&
        !isStorageRoute &&
        !isLtdPartner) ||
      (!appStore.withDefaultDeal &&
        appStore.with_ltd &&
        !isStorageRoute &&
        appStore.currentPlayer?.ai_type !== AI_TYPE.FIFTH_SHOP);
  }
  if (lockIconFlag) {
    // если ему не нужно видеть эту область и у него нет активного проссматриваемого лота
    // или он у конкурента
    return (
      <div className="lot-area lot-area--lot lot-area--lot-disallow">
        <LockIcon />
      </div>
    );
  }

  return (
    <DropContainer
      type={!storageCats ? DRAG_AREA.LOT : DRAG_AREA.STORAGE}
      element={({ canDrop, drop }) => (
        <div
          className="lot-area lot-area--lot"
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #a4c3ae',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          <div className="lot-wrapper">
            <LotAreaInfo />

            {!groupedCats.length && (
              <div className="lot-area--lot__text">
                <p>ПЕРЕТАЩИ КОТИКОВ ДЛЯ НАЧАЛА ТОРГОВЛИ</p>
              </div>
            )}

            {!!groupedCats.length && (
              <Swiper
                watchOverflow={!dragAroundForCatsInLotField}
                touchStartPreventDefault={false}
                threshold={0}
                touchAngle={40}
                freeModeMomentumRatio={0.2}
                freeModeMomentumVelocityRatio={0.2}
                slidesPerView={'auto'}
                freeMode={true}
                grabCursor={true}
                centerInsufficientSlides={true}
                className="lot-area__cats"
              >
                {groupedCats.map((item) => (
                  <SwiperSlide key={item.gender + item.color} className="lot-area__cats-slide">
                    <Cat
                      dragAround={dragAroundForCatsInLotField}
                      from={fromType}
                      onClick={() => {
                        if (!storageCats) {
                          appStore.setOpenedGroupedCat({
                            gender: item.gender,
                            color: item.color,
                            fromType: DRAG_AREA.LOT,
                          });
                        } else if (item.factCount !== 0) {
                          appStore.setOpenedGroupedCat({
                            gender: item.gender,
                            color: item.color,
                            fromType: DRAG_AREA.STORAGE,
                          });
                        }
                      }}
                      onDoubleClick={() => {
                        if (!storageCats) {
                          appStore.removeCatFromLot(item.contents ? item.contents[0] : item);
                        } else if (
                          appStore.me?.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT
                        ) {
                          return null;
                        } else if (storageCats && draggableOptionsDefault.canDrag(item, DRAG_AREA.STORAGE)) {
                          appStore.dropItem({ data: item, to: DRAG_AREA.STORAGE_MY_CATS, from: DRAG_AREA.STORAGE });
                        }
                      }}
                      onDeleteClick={() => {
                        appStore.removeCatsFromLot(item);
                      }}
                      readonly={isOutcoming || appStore.meIsCEO}
                      cat={item}
                      state={!storageCats ? CAT_STATE.LOT : CAT_STATE.STORAGE}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <LotPanel />
          </div>
          <div className="paw-grid-container">
            <PawGrid />
          </div>
        </div>
      )}
    />
  );
}

export default observer(LotField);
